import { transpile } from 'typescript';
import GSheetProcessor from '../src/gsheetsprocessor.js';
const sheetId = '1Za-pOqMRnIptdAPSXxe61PwODKtpmDmKyJfAZccd4ZQ';
const apiKey = 'AIzaSyDkNrmT5vPWakc4rVGt-ML-cdc8NKQrHx8';
const options = {
	apiKey: apiKey,
	sheetId: sheetId,
	sheetNumber: 1,
	returnAllResults: true,
	filter: {
		Description: 'Creative 1'
	},
	filterOptions: {
		operator: 'or',
		matching: 'loose',
	}
};
GSheetProcessor(options, results => {
	const project = document.querySelector('.project-list');
  
	let html = project.innerHTML;
	results.forEach((result, index) => {
		// html += `<div class="row">
		// 					<div class="col-md mb-5">
		// 						<h2>${result['Title']}</h2>
		// 						<div class="description">
		// 							<p>${result['Description']}</p>
		// 						</div>
		// 					</div>`;
		html += `<div class="modal slow-fade" id="modalTarget-${index}" tabindex="${index}" aria-labelledby="ModalLabel-${index}" aria-hidden="true">
					<div class="modal-dialog">
						${result['Description']}
					</div>
				</div>`

		if (result['Asset Type'] == 'Image') {
			html += `
					<div class="col-md">
						<div class="project-image-container" data-bs-toggle="modal" data-bs-target="#modalTarget-${index}">
							<img class="project-image img-fluid w-100" loading="lazy" src="https://mccanndesign.s3.amazonaws.com/${result['Asset ID']}" alt="Project Image" />
							<div class="project-title">${result['Title']}</div>
						</div>
					</div>`;
		}
		// src="https://player.vimeo.com/video/${result['Asset ID']}?api=1&background=1&autoplay=1&loop=1"
		// data-vimeo-id="${result['Asset ID']}" 
		if (result['Asset Type'] == 'Video') {
			html += `
					<div class="col-md video-column">
						<div class="project-video-container" data-bs-toggle="modal" data-bs-target="#modalTarget-${index}">
							<iframe class="project-video" loading="lazy" src="https://player.vimeo.com/video/${result['Asset ID']}?background=1" frameborder="0" allow="autoplay"></iframe>
							<div class="project-title">${result['Title']}</div>
						</div>
					</div>`;
		}
	});
	project.innerHTML = html;
  	project.classList.add('active');

	// Dynamically set image for twitter card. 
	const firstImg = results.find(e => e['Asset Type'] === 'Image')
	document.getElementsByTagName('meta')["twitter:image:src"].setAttribute(['content'], `https://mccanndesign.s3.amazonaws.com/${firstImg['Asset ID']}`)

}, error => {
	console.log('Error from sheets API: ', error);
})


// There is a bug on IOS where the modal will scroll with the bootstrap modal class.
// This is supposed to adjust for the error.
let previousScrollY = 0;

$(document).on('show.bs.modal', () => {
    previousScrollY = window.scrollY;
    $('html').addClass('modal-open').css({
        marginTop: -previousScrollY,
        overflow: 'hidden',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        // position: 'fixed',
    });
}).on('hidden.bs.modal', () => {
    $('html').removeClass('modal-open').css({
        marginTop: 0,
        overflow: 'visible',
        left: 'auto',
        right: 'auto',
        top: 'auto',
        bottom: 'auto',
        // position: 'static',
    });
    window.scrollTo({ left: 0, top: previousScrollY, behavior: "instant"} );
});